<template>
  <div class="TitleBox">
    <div v-if="content" class="TitleBoxContainer">
      <b>{{ content.title }}</b>
      <slot></slot>
      <div class="list">
        <ol v-if="ordered" :style="additionalStyle">
          <li v-for="(list, index) in content.paragraphs" :key="index">
            {{ list.text }}
            <ol v-if="list.children" class="ol-child" type="a">
              <li v-for="(child, cIndex) in list.children" :key="cIndex">
                {{ child }}
              </li>
            </ol>
          </li>
        </ol>
        <ul v-else :style="additionalStyle">
          <li v-for="(list, index) in content.paragraphs" :key="index">
            {{ list.text }}
            <ol v-if="list.children" class="ol-child" type="a">
              <li v-for="(child, cIndex) in list.children" :key="cIndex">
                {{ child }}
              </li>
            </ol>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleBox",
  props: {
    content: {
      type: [Object],
      default: null
    },
    additionalStyle: {
      type: [Object],
      default: null
    },
    ordered: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style scoped lang="scss">
  .TitleBox {
    .TitleBoxContainer {
      display: flex;
      flex-direction: column;
      .list {
        margin-top: 5px;
      }
    }
    p {
      margin-top: 13px;
      margin-bottom: 0!important;
    }
  }
</style>