<template>
  <div class="SurveyFile">
    <div
      class="back"
      @click="backToAllSurvey"
    >
      < Back to all surveys
    </div>
    <div class="downloadAndHistory">
      <div>
        <TitleBox
          :content="downloadTitle"
        >
          <span style="margin: 13px 0">When downloading the CSV file:</span>
        </TitleBox>
        <CButton
          class="downloadBtn btn-cus"
          color="primary"
          variant="outline"
          @click="download"
        >
          Download
        </CButton>
      </div>
      <div class="history">
        <p>Click the button below to retrieve the Uploaded and downloaded history from the last 90 days:</p>
        <CButton
          class="historyBtn btn-cus"
          color="primary"
          variant="outline"
          @click="downloadHistory"
        >
          Download history
        </CButton>
      </div>
    </div>
    <div class="row-full"></div>
    <div class="upload">
      <TitleBox
        :content="uploadTitle"
        :additional-style="{listStyleType: 'none', padding: 0}"
      />
      <input
        v-show="false"
        id="uploadZip"
        ref="uploadZip"
        accept="application/zip"
        type="file"
        @change="upload"
      />
      <div class="uploadBtn">
        <CButton
          class="uploadBtn btn-cus"
          color="primary"
          @click="openUploadInput"
        >
          Update and replace data
        </CButton>
      </div>
      <TitleBox 
        :content="lists"
        :ordered="true"
      />
    </div>
    <div v-if="showModalUploadRule">
      <transition name="modal">
        <div class="modal-mask-confirm">
          <div class="modal-wrapper">
            <div
              class="modal-dialog"
              role="document"
            >
              <div class="modal-content custom-dialog-confirm">
                <div class="modal-body">
                  <div class="flex flex-column title-text-modal-confirm">
                    <p>Upload Error!</p>
                    <p>It could be due to one or more of the following reason</p>
                  </div>
                  <div class="flex flex-column body-text-modal-confirm">
                    <ol type="1">
                      <li>Only zip file can be uploaded</li>
                    </ol>
                  </div>
                  <div class="btn-modal-confirm">
                    <button
                      class="btn btn-primary"
                      style="color:#fff; width: 127px; height: 40px;"
                      type="button"
                      @click="showModalUploadRule = false"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import TitleBox from './SurveyFile/TitleBox'
import SurveyService from "../../../service/SurveyService";
import UploadFileService from "../../../service/UploadFileService";

export default {
  name: "SurveyFile",
  components: {
    TitleBox
  },
  data() {
    return {
      lists: {
        title: 'How to edit the stored data?',
        paragraphs: [
          {
            text: 'Download the CSV data',
          },
          {
            text: 'Unzip the downloaded file',
          },
          {
            text: 'There are 2 CSV files in the folder: before_login and after_login',
            children: [
              'before_login: surveys done on browser or without logging into the application.',
              'after_login: surveys done in-stores after the ECP has logged into the application.',
              'Only edit, add or delete the data, do not make any changes to the column name and order.'
            ]
          },
          {
            text: 'When edits are completed, DO NOT rename the files. They should be named respectively,',
            children: [
              'before_login',
              'after_login'
            ]
          },
          {
            text: 'Save each file as a .CSV format.',
          },
          {
            text: 'Zip the 2 files together.',
          },
          {
            text: 'Click on the solid blue button “Upload and replace data” and upload the zipped file.',
          },
          {
            text: 'When successful, the data will be replaced',
          },
        ]
      },
      downloadTitle: {
        title: 'Click on the Download button, to obtain customer’s data.',
        paragraphs: [
          {text: 'The downloaded CSV file must be deleted before 1 year of aging.'},
          {text: 'In case you need to download the CSV file, the CSV file cannot be shared and have to stay confidential in order to protect data users, please contact Global Marketing if you have any requests.'},
          {text: 'For CSV files used for email address data collection in order to grow the newsletter list, be sure to check the mailbox weekly for any unsubscribe requests, you must ensure that the CSV file is also updated with your newsletter file.'},
          {text: 'For deletion requests, follow the "How to edit" instructions, delete the data concerned, replace the existing CSV file, ensure that the list of newsletters is also updated.'},
          {text: 'For user data requests, download and delete data from the CSV file of all other users and only share user data involved.'},
        ]
      },
      uploadTitle: {
        title: 'To edit the stored data, use the function below:',
        paragraphs: [
          {
            text: 'It is recommended to modify the data only after the optical store’s closing hours.',
          },
          {
            text: 'Note that the system does not verify the uploaded contents. Please be sure NOT to edit the template layout to prevent errors in the database.',
          },
        ]
      },
      showModalUploadRule: false,
    }
  },
  computed: {
    item() {
      return this.$store.state.survey.surveyFileItem
    }
  },
  created() {
    window.scrollTo(0, 0);
  },
  methods: {
    backToAllSurvey() {
      this.$emit('closeFileSection')
    },
    openUploadInput() {
      this.$refs.uploadZip.click()
    },
    download() {
      if (this.item) {
        SurveyService.download(this.item.id)
            .then((response) => {
              const fileURL = window.URL.createObjectURL(new Blob([response.data]));
              const fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute('download', response.headers.filename);
              document.body.appendChild(fileLink);

              fileLink.click();
            })
      }
    },
    upload(e) {
      this.showModalUploadRule = false
      const loader = this.$loading.show();
      const {name} = e.target.files[0];

      if (!this.isZipFileExtension(name)) {
        this.showModalUploadRule = true
        loader.hide()
      } else {
        UploadFileService.uploadSurvey(e.target.files[0], this.item.id, (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        }).then(() => {
          loader.hide()
        }).catch(() => {
          loader.hide()
        })
      }

      this.$refs.uploadZip.value = null
    },
    openToast(message) {
      console.log(message)
    },
    isZipFileExtension(name) {
      const split = name.split('.')
      return split.length > 1 && split[1].toLowerCase() === 'zip'
    },
    downloadHistory() {
      if (this.item) {
        SurveyService.downloadHistory(this.item.id)
            .then((response) => {
              const fileURL = window.URL.createObjectURL(new Blob([response.data]));
              const fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute('download', response.headers.filename);
              document.body.appendChild(fileLink);

              fileLink.click();
            })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.SurveyFile {
  button {
    margin: 0
  }

  .back {
    cursor: pointer;
  }

  .downloadBtn, .historyBtn, .uploadBtn {
    width: 228px !important;
    height: 39px;
  }

  .downloadAndHistory {
    margin-top: 27px;
    display: flex;
    justify-content: space-between;

    .downloadBtn {
      margin-top: 19px;
    }

    .history {
      width: 242px;

      p {
        font-size: 11px;
        line-height: 16.5px;
        margin-bottom: 5px;
      }
    }
  }

  .upload {
    display: flex;
    flex-direction: column;

    .listTitle {
      margin-bottom: 10px;
    }

    .uploadBtn {
      margin-top: 24px;
      margin-bottom: 35px;
    }

    ol {
      padding-left: 16px;
    }

    .ol-child {
      padding-left: 20px;
    }
  }

  .row-full {
    width: 100vw;
    position: relative;
    margin-left: -50vw;
    left: 50%;
    border-top: 1px solid #000;
    margin-bottom: 40px;
    margin-top: 55px
  }

  .modal-mask-confirm {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hsla(0, 0%, 71%, 0.5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .title-text-modal-confirm {
    margin: 0 auto;
    width: 478px;
    height: 58px;
    text-align: center;
    margin-bottom: 18px;
    font-size: 15px;
    line-height: 25px;
    color: white;
  }

  .body-text-modal-confirm {
    margin: 0 auto;
    width: 514px;
    height: 92px;
    margin-bottom: 28px;
    font-size: 15px;
    line-height: 25px;
    color: white;

    ol {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .btn-modal-confirm {
    display: flex;
    justify-content: center;
  }

  .custom-dialog-confirm {
    width: 625px;
    height: 283px;
    background: #4B4B4B;
  }
}
</style>