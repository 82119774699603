import Vue from "vue";
import { http } from "../plugin/http2-common";

const BASE_URL = "/user";

class ManageUserService {
  getAll() {
    return http.get(`${BASE_URL}`);
  }

  getByUsername(username) {
    return http.get(`${BASE_URL}/${username}`);
  }

  upsert(data) {
    data = data
    .filter((i) => i.email && i.role)
    .map((i) => {
      return {
        email: i.email,
        role: i.role,
        countryCode: i.countryCode,
        newUser: !!i.isNew,
        dataAccess: i.dataAccess,
        dataAccessCountryList: i.dataAccessCountryList,
        tokenAzure: Vue.$cookies.get('token_azure')
      };
    });
    return http.post(`${BASE_URL}/upsert`, data);
  }

  delete(users) {
    return http.post(`${BASE_URL}/delete`, { users });
  }
}

export default new ManageUserService();
