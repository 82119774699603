<template>
  <div class="mt-3 common-department">
    <SurveyFile
      v-if="surveyFileItem"
      :item="surveyFileItem"
      @closeFileSection="closeFileSection"
    />
    <SurveyAdminList
      v-else
      @openFileSection="openFileSection"
    />
  </div>
</template>
<script>

import SurveyAdminList from "./components/SurveyAdminList";
import SurveyFile from "./components/SurveyFile";

export default {
  name: "SurveyCreation",
  components: {
    SurveyAdminList,
    SurveyFile
  },
  data() {
    return {
      openingFile: false,
    };
  },
  computed: {
    surveyFileItem() {
      return this.$store.state.survey.surveyFileItem
    }
  },
  methods: {
    openFileSection(item) {
      this.$store.commit('survey/changeSurveyFileItem', item)
    },
    closeFileSection() {
      this.$store.commit('survey/changeSurveyFileItem', null)
    },
  },
};
</script>

<style scoped>

</style>
