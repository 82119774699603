<template>
  <div class="SurveyAdminList">
    <div class="form-inline d-flex flex-row justify-content-between mb-2">
      <div>
        <p class="m-0">
          Customize and localize survey contents.
        </p>
        <p class="d-flex pl-2">
          <span class="pr-2" style="font-size: 30px;">•</span> Note that the survey's language and country can only be modified when they are not utilized in a survey package.
        </p>
      </div>
      <div class="input-group ml-2">
        <input
          id="example-search-input"
          v-model="searchText"
          class="form-control py-2 border-right-0 border"
          type="text"
          @keyup="search"
        />
        <span class="input-group-append">
          <button
            class="btn btn-outline-secondary border-left-0 border"
            type="button"
          >
            <b-icon
              class="form-control-feedback"
              icon="search"
              @click="search"
            />
          </button>
        </span>
      </div>
    </div>
    <div class="table-responsive">
      <table
        id="table-edit"
        ref="searchTable"
        class="table table-sm table-center"
      >
        <thead>
          <tr>
            <th class="normal-th-content">
              SN
            </th>
            <th class="normal-th-content">
              ID
            </th>
            <th class="normal-th-content">
              Country
            </th>
            <th class="normal-th-content">
              Language
            </th>
            <th class="normal-th-content">
              Access
            </th>
            <th class="normal-th-content">
              Survey Description
            </th>
            <th class="normal-th-content">
              Edit
            </th>
            <th class="normal-th-content dark-gray">
              Last Edit
            </th>
            <th class="normal-th-content dark-gray">
            </th>
            <th class="normal-th-content dark-gray">
              Survey Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="item.id">
            <td>{{ index + 1 }}</td>
            <td>{{ item.snAsText }}</td>
            <template v-if="!item.editable">
              <td>{{ item.country.name }}</td>
              <td>{{ languages[item.languageCode] }}</td>
              <td>{{ item.access }}</td>
              <td class="text-left" :style="`max-width: 500px;word-break: break-word;white-space: ${item.description.length > 30 ? 'unset' : 'nowrap' };`">
                {{ item.description }}
              </td>
              <td class="edit-col">
                <button
                  :disabled="(!item.editable && isEditing) || ((item.id == 1 || item.parentId == 1) && !isAdmin)"
                  class="btn btn-link btn-icon"
                  type="button"
                  @click="edit(item)"
                >
                  <b-icon class="pointer text-dark" icon="pencil-fill" />
                </button>
              </td>
            </template>
            <template v-else>
              <td style="max-width: 200px;">
                <select
                  v-if="item.isInPackage"
                  v-model="item.countryCode"
                  class="form-control"
                  :disabled="item.isInPackage"
                >
                  <option
                    v-for="(value, key) in countriesFull"
                    :key="key"
                    :value="key"
                  >
                    {{ value }}
                  </option>
                </select>
                <select
                  v-else
                  v-model="item.countryCode"
                  class="form-control"
                  :disabled="item.isInPackage"
                >
                  <option
                    v-for="(value, key) in countriesLensAccess"
                    :key="key"
                    :value="key"
                  >
                    {{ value }}
                  </option>
                </select>
              </td>
              <td style="max-width: 200px;">
                <select v-model="item.languageCode" class="form-control" :disabled="item.isInPackage">
                  <option
                    v-for="(value, key) in languages"
                    :key="key"
                    :value="key"
                  >
                    {{ value }}
                  </option>
                </select>
              </td>
              <td>
                <select v-model="item.access" class="form-control">
                  <option
                    v-for="(value, key) in accesses"
                    :key="key"
                    :value="key"
                  >
                    {{ value }}
                  </option>
                </select>
              </td>
              <td>
                <textarea-autosize
                  v-model="item.description"
                  class="form-control text-left"
                  rows="1"
                />
              </td>
              <td class="edit-col">
                <button
                  class="btn btn-link btn-icon text-success"
                  type="button"
                  @click="save(item)"
                >
                  <b-icon class="pointer text-success" icon="check-circle" />
                </button>
                <button
                  class="btn btn-link btn-icon text-danger ml-2"
                  type="button"
                  @click="unSave()"
                >
                  <b-icon icon="x-circle" />
                </button>
              </td>
            </template>
            <td>
              {{ item.updatedAt | formatDate }}
            </td>
            <td>
              <span v-if="!item.published && !item.isCreated && !((item.id == 1 || item.parentId == 1) && !isAdmin)">
                <CButton
                  :disabled="isEditing"
                  class="btn-cus"
                  color="warning ml-1"
                  size="sm"
                  @click="publish(item)"
                >
                  Publish
                </CButton>
              </span>
            </td>
            <td style="min-width: 265px;">
              <template v-if="!item.isCreated ">
                <button v-if="item.showURL || showSpacesForNoURL"
                        :disabled="isEditing || !item.showURL"
                        class="btn btn-link btn-icon"
                        type="button"
                        :style="{visibility: item.showURL ? 'visible' : 'hidden'}"
                        @click="view(item)"
                >
                  <b-icon :class="item.showURL ? 'text-dark' : 'text-secondary'" icon="eye-fill" />
                </button>
                <CButton 
                  :disabled="isEditing"
                  class="mr-1 ml-1 btn-cus"
                  color="primary"
                  size="sm"
                  :style="{visibility: ((item.id == 1 || item.parentId == 1) && !isAdmin) ? 'hidden' : 'visible'}"
                  @click="forward(item)"
                >
                  Edit
                </CButton>
                <CButton v-if="item.showURL || showSpacesForNoURL"
                         :disabled="isEditing || !item.showURL"
                         class="mr-1 ml-1 btn-cus"
                         :color="item.showURL ? 'primary' : 'secondary'"
                         :style="{visibility: item.showURL ? 'visible' : 'hidden'}"
                         size="sm"
                         @click="url(item)"
                >
                  URL
                </CButton>
                <CButton
                  :disabled="isEditing"
                  class="mr-1 ml-1 btn-cus"
                  color="primary"
                  size="sm"
                  @click="duplicate(item)"
                >
                  Duplicate
                </CButton>
                <CButton v-if="isDataAccess && !((item.id == 1 || item.parentId == 1) && !isAdmin)"
                         :disabled="isEditing"
                         class="mr-1 ml-1 btn-cus"
                         color="primary"
                         size="sm"
                         @click="open(item)"
                >
                  Data
                </CButton>
              </template>
              <button
                :disabled="item.id == 1 || item.parentId == 1 || isEditing"
                class="btn btn-link btn-icon text-danger"
                type="button"
                @click="remove(item)"
              >
                <b-icon icon="x-circle" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { SURVEY_FE_URL } from "@/constants/Paths";
import { ROLE_ADMIN } from "@/constants/UserRole";
import ArrayUtils from "../../../common/ArrayUtils";
import { fixHeaderTable } from "../../../common/CommonUtils";
import ManageService from "../../../service/ManageUserService";
import SurveyService from "../../../service/SurveyService";

export default {
  name: "SurveyAdminList",
  data() {
    return {
      isDataAccess: false,
      items: [],
      originalItems: [],
      searchText: "",
      allItems: [],
      showSpacesForNoURL: false,
    };
  },
  computed: {
    countriesLensAccess() {
      const user = this.$store.state.auth.user
      const countriesFilter = this.$store.state.common.countries
      if (user.role !== ROLE_ADMIN) {
        const codes = user.lensSelectorAcccessCountries.split(";");
        return  Object.keys(countriesFilter)
        .filter(key => codes.includes(key))
        .reduce((obj, key) => {
          obj[key] = countriesFilter[key];
          return obj;
        }, {});
      }
      return this.$store.state.common.countries;
    },
    countriesFull() {
      const user = this.$store.state.auth.user
      const countriesFilter = this.$store.state.common.countries
      if (user.role !== ROLE_ADMIN) {
        const codes = user.dataAccessCountryList.split(";");
        return  Object.keys(countriesFilter)
        .filter(key => codes.includes(key))
        .reduce((obj, key) => {
          obj[key] = countriesFilter[key];
          return obj;
        }, {});
      }
      return this.$store.state.common.countries;
    },

    languages() {
      const disabledLanguageCodes = ["am", "az", "dv", "he", "ku", "fa", "ur"];
      console.log(this.$store.state.common.languages);
      const allLanguages = this.$store.state.common.languages;
      return  Object.keys(allLanguages)
        .filter(key => !disabledLanguageCodes.includes(key))
        .reduce((obj, key) => {
          obj[key] = allLanguages[key];
          return obj;
        }, {});
    },
    accesses() {
      return this.$store.state.common.accesses;
    },
    searchKeys() {
      return ["countryCode"];
    },
    isEditing() {
      return this.items.some((item) => item.editable);
    },
    isAdmin() {
      return this.$store.state.auth.user.role == ROLE_ADMIN;
    }
  },
  mounted() {
    this.getAll();
  },
  updated() {
    fixHeaderTable("table-edit", "header-nav", false);
  },
  methods: {
    search() {
      if (!this.searchText) {
        this.searchText = "";
      }
      const searchTable = this.$refs.searchTable;
      const trs = searchTable.getElementsByTagName("tr");
      for (let i = 0; i < trs.length; i++) {
        let tr = trs[i];
        let styleDisplay = this.searchInTd(tr, 1);
        if (styleDisplay) {
          styleDisplay = this.searchInTd(tr, 2);
        }
        if (styleDisplay) {
          styleDisplay = this.searchInTd(tr, 3);
        }
        if (styleDisplay) {
          styleDisplay = this.searchInTd(tr, 4);
        }
        if (styleDisplay) {
          styleDisplay = this.searchInTd(tr, 5);
        }
        if (styleDisplay) {
          styleDisplay = this.searchInTd(tr, 7);
        }
      }
    },
    searchInTd(tr, index) {
      let td = tr.getElementsByTagName("td")[index];
      let styleDisplay;
      if (td) {
        let txtValue = td.textContent || td.innerText || "";
        styleDisplay =
            txtValue.toUpperCase().indexOf(this.searchText.toUpperCase()) > -1
                ? ""
                : "none";
        tr.style.display = styleDisplay;
      }
      return styleDisplay;
    },
    getAll() {
      let loader = this.$loading.show();
      SurveyService.getAll()
          .then((response) => {
            const user = this.$store.state.auth.user;
            ManageService.getByUsername(user.username)
            .then((response) => {
              if(response.data.dataAccess === 1){
                this.isDataAccess = true
              } else {
                this.isDataAccess = false;
              }
              loader.hide();
            })
            .catch(this.catchError)
            .finally(() => {
              loader.hide();
            });
            loader.hide();
            this.items = response.data;
            this.showSpacesForNoURL = this.items.find(item => item.showURL == true) !== undefined;
            this.originalItems = ArrayUtils.clone(this.items);
            this.refresh();
          })
          .catch((reason) => {
            loader.hide();
            const data = reason.response.data;
            if (data) {
              this.openToast(data.message);
            } else {
              this.openToast("Server error");
            }
          });
    },
    edit(item) {
      item.editable = true;
      this.refresh();
    },
    save(survey) {
      let loader = this.$loading.show();
      SurveyService.update(survey)
          .then(() => {
            this.getAll();
          })
          .catch((reason) => {
            const data = reason.response.data;
            if (data) {
              this.openToast(data.message);
            } else {
              this.openToast("Server error");
            }
          })
          .finally(() => {
            loader.hide();
          });
    },
    unSave() {
      this.items = ArrayUtils.clone(this.originalItems);
    },
    view(item) {
      window.open(this.getSurveyUrl(item));
    },
    url(item) {
      // this.$copyText("Text to copy", container);
      // this.$clipboard(this.getSurveyUrl(item));
      this.$copyText(this.getSurveyUrl(item)).then(
          () => {
            this.$toast.open({
              message: "Copied survey URL successfully!",
            });
          },
          (err) => {
            console.log(err);
            this.$toast.open({
              message: "Can not copy survey URL.",
              type: "error",
            });
          }
      );
    },
    getSurveyUrl(item) {
      return `${SURVEY_FE_URL}?surveyId=${item.parentId || item.id}`;
    },
    forward(item) {
      let loader = this.$loading.show();
      SurveyService.edit(item.id)
          .then(({data}) => {
            this.$store.commit("survey/save", {
              id: data.id,
              parentId: data.parentId,
              country: {
                code: data.countryCode,
                name: this.countriesFull[data.countryCode],
              },
              language: {
                code: data.languageCode,
                name: this.languages[data.languageCode],
              },
              published: data.published,
              content: data,
              showURL: data.showURL,
            });
            this.$router.push("/database/product-database");
          })
          .catch((reason) => {
            console.log(reason);
          })
          .finally(() => {
            loader.hide();
          });

    },
    open(item) {
      this.$emit('openFileSection', item)
    },
    duplicate(item) {
      let loader = this.$loading.show();
      SurveyService.duplicate(item.id)
          .then(() => {
            this.getAll();
          })
          .catch((reason) => {
            const data = reason.response.data;
            if (data) {
              this.openToast(data.message);
            } else {
              this.openToast("Server error");
            }
          })
          .finally(() => {
            loader.hide();
          });
    },
    remove(item) {
      let loader = this.$loading.show();
      SurveyService.remove(item.id)
          .then(() => {
            this.getAll();
          })
          .catch((reason) => {
            const data = reason.response.data;
            if (data) {
              this.openToast(data.message);
            } else {
              this.openToast("Server error");
            }
          })
          .finally(() => {
            loader.hide();
          });
    },
    publish(survey) {
      let loader = this.$loading.show();
      SurveyService.publish(survey.id)
          .then(() => {
            this.getAll()
          })
          .catch((reason) => {
            const data = reason.response.data;
            if (data) {
              this.openToast(data.message);
            } else {
              this.openToast("Server error");
            }
          })
          .finally(() => {
            loader.hide();
          });
    },
    refresh() {
      this.items = ArrayUtils.clone(this.items);
      this.resetAllItems();
    },
    replaceAndrefresh(data, survey) {
      this.items = this.items.map((item) => {
        if (JSON.stringify(item) == JSON.stringify(survey)) {
          return data;
        }
        return item;
      });
      this.resetAllItems();
    },
    resetAllItems() {
      this.allItems = [...this.items];
    },
    openToast(message) {
      console.log(message);
    },
  },
};
</script>

<style scoped>
.edit-col {
  width: 75px;
}

.edit-col .btn {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

.btn-icon {
  padding: 0 !important;
  cursor: pointer !important;
  pointer-events: inherit !important;
  width: fit-content !important;
}

.SurveyAdminList #table-edit td {
  white-space: nowrap;
  vertical-align: middle;
}

.SurveyAdminList .table-responsive::-webkit-scrollbar {
  height: 8px;
  background-color: #f1f1f1;
}

.SurveyAdminList .table-responsive::-webkit-scrollbar-thumb {
  background-color: #d0d0d0;
  border-radius: 15px;
}

/* Handle on hover */
.SurveyAdminList .table-responsive::-webkit-scrollbar-thumb:hover {
  background: #a9a9a9; 
}
  
</style>
